import useCurrentProject from "../../../hooks/useCurrentProject";
import useProjectLastScan from "../../../hooks/useProjectLastScan";
import useReporter from "../../../hooks/useReporter";
import useScaner from "../../../hooks/useScaner";

const CheckAccessHomeView: React.FC = () => {
  const project = useCurrentProject();
  const { startScan, isScaning } = useScaner();
  const { lastScanReport, neededInterventions } = useReporter();
  const { lastScan } = useProjectLastScan(project.id);

  return (
    <div className="flex h-full justify-center p-6 pt-10">
      <h3>{project?.name}</h3>
      <button onClick={() => startScan(project)}>Start Scan</button>
      {isScaning && <div>Scaning....</div>}
      {lastScanReport && (
        <div>
          <h3>Scan Report</h3>
          <pre>{JSON.stringify(lastScanReport, null, 2)}</pre>
        </div>
      )}
      {neededInterventions && (
        <div>
          <h3>Needed Interventions</h3>
          <pre>{JSON.stringify(neededInterventions, null, 2)}</pre>
        </div>
      )}
      {lastScan && (
        <div>
          <h3>Last Scan</h3>
          <pre>{JSON.stringify(lastScan, null, 2)}</pre>
        </div>
      
      )}
    </div>
  );
};

export default CheckAccessHomeView;
