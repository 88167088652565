import React from "react";
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from "react-router-dom";
import ProjectsDashboardView from "./pages/projects/ProjectsDashboardView";
import CheckAccessHomeView from "./pages/project/check-access/CheckAccessHomeView";
import ProjectDashboardRootView from "./pages/project/ProjectDashboardRootView";
import ProjectDashboardHomeView from "./pages/project/ProjectDashboardHomeView";
import AdaptifyAnalyticsGeneralView from "./pages/project/adaptify/AdaptifyAnalyticsGeneralView";
import AdaptifyAnalyticsAdaptifyUsage from "./pages/project/adaptify/AdaptifyAnalyticsAdaptifyUsage";
import { AnalyticsMetricsProvider } from "./providers/AnalyticsMetricsProvider";
import ProjectMainDashboardRootView from "./pages/projects/ProjectMainDashboardRootView";
import CrispChat from "components/CrispChat";
import ErrorFallback from "components/ErrorFallback";

export const PROJECTS_URL = "/dashboard/projects";

export const CHECK_ACCESS_URL = "ca";
export const ADAPTIFY_ANALYTICS_URL = "analytics";
export const ADAPTIFY_ANALYTICS_USAGE_URL = "analyticsusage";

export const createProjectRoute = (projectId: string, nested?: string) => `${PROJECTS_URL}/${projectId}/${nested || ''}`; 

const checkAccessRoutes: RouteObject[] = [
  {
    path: CHECK_ACCESS_URL,
    element: <CheckAccessHomeView />,
  }
];

const adaptifyAnalyticsRoutes: RouteObject[] = [
  {
    path: ADAPTIFY_ANALYTICS_URL,
    index: true,
    element: (
      <AnalyticsMetricsProvider>
        <AdaptifyAnalyticsGeneralView />
      </AnalyticsMetricsProvider>
    ),
  }
];


const adaptifyAnalyticsUsageRoutes: RouteObject[] = [
  {
    path: ADAPTIFY_ANALYTICS_USAGE_URL,
    index: true,
    element: (
      <AnalyticsMetricsProvider>
        <AdaptifyAnalyticsAdaptifyUsage />
      </AnalyticsMetricsProvider>
    ),
  }
];



const router = createBrowserRouter([
  { 
    path: "/", 
    element: <Navigate to={PROJECTS_URL} replace />,
    errorElement: <ErrorFallback />,
  },
  {
    path: `${PROJECTS_URL}/:projectId`,
    element: <ProjectDashboardRootView />,
    errorElement: <ErrorFallback />,
    children: [
      {
        index: true,
        element: <ProjectDashboardHomeView />, 
      },
      ...checkAccessRoutes,
      ...adaptifyAnalyticsRoutes,
      ...adaptifyAnalyticsUsageRoutes,
    ]
  },
  {
    path: PROJECTS_URL,
    element: <ProjectMainDashboardRootView />,
    errorElement: <ErrorFallback />,
    children: [
      {
        index: true,
        element: <ProjectsDashboardView />, 
      },
    ]
  }
]);

const Router: React.FC = () => {
  return (
    <>
      <RouterProvider router={router} />
      <CrispChat />
    </>
    );
};

export default Router;
