import useMetric from "../../hooks/useMetric";
import MetricCard from "./MetricCard";
import WebAssetIcon from '@mui/icons-material/WebAsset';
import TableView from "./TableView";



const PageWithMostAdaptifyUsage = () => {
  const metric = useMetric("pages_with_most_adaptify_usage");

  return (
    <MetricCard title="Page avec le plus d'utilisation d'Adaptify" Icon={<WebAssetIcon />}>
      {metric && metric.length > 0 && (
        <TableView className="max-h-[150px]" row={metric.map(el=>({page_url: el.page_url, num: el.usage_count}))} />
      )}
      {metric && metric.length === 0 && <div>No data</div>}
    </MetricCard>
  );
};

export default PageWithMostAdaptifyUsage;
