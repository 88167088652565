import { Outlet } from 'react-router-dom';
import { Sidebar } from '../../components/Sidebar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import ProjectDashboardAppBar from '../../components/ProjectDashboardAppBar';

const SIDEBAR_WIDTH = 280;

const ProjectDashboardRootView: React.FC = () => {
  return (
    <Box className="flex w-full">
      <CssBaseline />
      <Sidebar width={SIDEBAR_WIDTH} />
      <ProjectDashboardAppBar sidebarWidth={SIDEBAR_WIDTH} />
      <Box component="main" className='flex flex-col flex-grow h-screen'>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default ProjectDashboardRootView;
