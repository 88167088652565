import React, {
  useContext,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Step,
  StepLabel,
  Stepper,
  Box,
  Typography,
  LinearProgress,
  Input,
} from "@mui/material";
import { OrganizationsContext } from "providers/OrganizationsProvider";
import { getEnumValues } from "utils/enumUtils";
import useTechDetection from "hooks/useTechDetection";
import OnboardingDoucmentationStep from "./OnboardingDocumentationStep";

enum OnboardingFormSteps {
  Welcome = 0,
  Organization = 1,
  Project = 2,
  Documentation = 3,
  End = 4,
}

const STEPS_LABELS: Record<OnboardingFormSteps, string> = {
  [OnboardingFormSteps.Welcome]: "Bienvenue!",
  [OnboardingFormSteps.Organization]: "Organisation",
  [OnboardingFormSteps.Project]: "Projet",
  [OnboardingFormSteps.Documentation]: "Documentation",
  [OnboardingFormSteps.End]: "Fin",
};

const ALL_STEPS = getEnumValues(OnboardingFormSteps);

const computeProgress = (activeStep: OnboardingFormSteps) => {
  return ((activeStep + 1) / ALL_STEPS.length) * 100;
};

interface OnboardingFormProps {
  onClose: () => void;
}

const OnboardingForm: React.FC<OnboardingFormProps> = ({ onClose }) => {
  const { createOrganization } = useContext(OrganizationsContext)!;
  const {
    cms: detectedCms,
    error: techDetectionError,
    detectUrl,
  } = useTechDetection();

  const [activeStep, setActiveStep] = useState(OnboardingFormSteps.Welcome);

  const [organisationName, setOrganisationName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectUrl, setProjectUrl] = useState<URL | null>(null);
  const [showErrors, setShowErrors] = useState(false);

  const handleProjectUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setProjectUrl(new URL(e.target.value));
    } catch {
      setProjectUrl(null);
    }
  };

  const activeStepSanitizedError: string | null = useMemo(() => {
    switch (activeStep) {
      case OnboardingFormSteps.Documentation |
        OnboardingFormSteps.End |
        OnboardingFormSteps.Welcome:
        return null;

      case OnboardingFormSteps.Organization: {
        if (organisationName.trim().length === 0) {
          return "Le nom de l'organisation est requis";
        }
        return null;
      }

      case OnboardingFormSteps.Project: {
        if (projectName.trim().length === 0) {
          return "Le nom du projet est requis";
        }
        
        if (projectUrl === null) {
          return "Le lien du projet est requis et doit être un lien valide";
        }

        return null;
      }

      default:
        return null;
    }
  }, [activeStep, organisationName, projectName, projectUrl]);

  const errorMessage = activeStepSanitizedError || techDetectionError;

  const handleSubmit = async () => {
    if (!projectUrl) return;

    await createOrganization({
      organization: {
        name: organisationName,
      },
      projectPayload: {
        name: projectName,
        url: projectUrl,
      },
    });
  };

  const handleNext = () => {
    if (activeStepSanitizedError) {
      setShowErrors(true);
      return;
    } else if (showErrors) {
      setShowErrors(false);
    }

    if (activeStep === OnboardingFormSteps.End) {
      onClose();
      return;
    }

    if (activeStep === OnboardingFormSteps.Project) {
      detectUrl(projectUrl!).then((isDetected) => {
        if (!isDetected) {
          setShowErrors(true);
          return;
        }

        handleSubmit();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      });

      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === OnboardingFormSteps.Welcome) {
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box className="mx-6 flex flex-col absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[700px] h-[400px] bg-white border-2 rounded-3xl shadow-lg p-4">
      <Stepper activeStep={activeStep} alternativeLabel>
        {ALL_STEPS.map((step) => STEPS_LABELS[step]).map(
          (label) => (
            <Step 
              key={label}
              sx={{
                "& .MuiStepLabel-label": {
                  marginTop: "4px",
                },
              }}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          )
        )}
      </Stepper>

      <Box className="m-6 h-full flex flex-grow flex-col">
        {errorMessage && showErrors && <Typography sx={{marginBottom: "8px"}} className="text-red-500"><strong>{errorMessage}</strong></Typography>}
        {activeStep === OnboardingFormSteps.Welcome && (
          <Typography className="flex flex-col">
            <strong className="text-background text-2xl mb-2">Bienvenue sur Adaptify!</strong> 
            Pour commencer, veuillez remplir les informations suivantes.
          </Typography>
        )}
        {activeStep === OnboardingFormSteps.Organization && (
          <Typography className="flex flex-col">
            <label htmlFor="organizationName">Quel est le nom de votre entreprise?</label>
            <Input
              id="organizationName"
              name="organizationName"
              value={organisationName}
              onChange={(e) => setOrganisationName(e.target.value)}
            />
          </Typography>
        )}
        {activeStep === OnboardingFormSteps.Project && (
          <Typography className="flex flex-col">
            <label htmlFor="projectName">Quel est le nom de votre site internet?</label>
            <Input
              id="projectName"
              name="projectName"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />

            <label className="mt-4" htmlFor="projectUrl">Lien du site</label>
            <Input 
              id="projectUrl"
              name="projectUrl"
              placeholder="https://example.com"
              onChange={handleProjectUrlChange} 
            />
          </Typography>
        )}
        {activeStep === OnboardingFormSteps.Documentation && (
          <OnboardingDoucmentationStep detectedCms={detectedCms} />
        )}
        {activeStep === OnboardingFormSteps.End && (
          <Typography variant="h6">Formulaire complété !</Typography>
        )}
      </Box>

      <LinearProgress
        className="mx-6"
        variant="determinate"
        value={computeProgress(activeStep)}
        sx={{ my: 2 }}
      />

      <Box
        className="flex justify-center items-end mx-6"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mt: 1, mr: 1 }}
        >
          {activeStep < 2 && "Précédent"}
        </Button>
        <Button onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
          {activeStep === OnboardingFormSteps.End ? "Terminer" : "Suivant"}
        </Button>
      </Box>
    </Box>
  );
};

export default OnboardingForm;
