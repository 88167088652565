import { useEffect } from "react";
import useScaner from "./useScaner";

const useProjectLastScan = (projectId: string) => {
  const { 
    fetchLastScanFor, 
    getProjectLastScan, 
    fetchingLastScanProjectIds: fetchedLastScanProjectIds 
  } = useScaner();

  const lastScan = getProjectLastScan(projectId);
  const isFetchingLastScan = fetchedLastScanProjectIds.includes(projectId);
  const isLastScanNeedToBeFetched = lastScan === undefined && !isFetchingLastScan;

  useEffect(() => {
    if (isLastScanNeedToBeFetched) fetchLastScanFor(projectId);
  }, [fetchLastScanFor, isLastScanNeedToBeFetched, projectId]);

  return { lastScan, isFetchingLastScan }
};

export default useProjectLastScan;
