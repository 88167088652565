import { createContext, ReactNode, useState } from "react";
import Confetti from 'react-confetti';

type ConfettiContextProps = {
  launchConfetti: () => void;
};

type ConfettiProviderProps = {
  children: ReactNode;
};

export const ConfettiContext = createContext<ConfettiContextProps | null>(null);

const getConfettiSource = () => {
	const halfWidth = window.innerWidth / 2;
	const halfHeight = window.innerHeight / 2;

	const widthSize = window.innerWidth / 3;
	const heightSize = window.innerWidth / 3;

	return { 
		x: halfWidth - (widthSize / 2), 
		y: halfHeight - (heightSize / 1.3), 
		w: widthSize, 
		h: heightSize 
	};
}

const ConfettiProvider = ({ children }: ConfettiProviderProps) => {
  const [confettiVisible, setConfettiVisible] = useState(false);

  const launchConfetti = () => {
    setConfettiVisible(true);
  };

  return (
    <ConfettiContext.Provider value={{ launchConfetti }}>
      {children}
      {confettiVisible && <Confetti 
				style={{ zIndex: 9999 }}
				tweenDuration={500}
				numberOfPieces={200}
				recycle={false}
				confettiSource={getConfettiSource()}
				initialVelocityX={{ min: -15, max: 15 }}
				initialVelocityY={{ min: -20, max: 20 }}
				onConfettiComplete={() => setConfettiVisible(false)}
			/>}
    </ConfettiContext.Provider>
  );
};

export default ConfettiProvider;