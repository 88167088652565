import DnsOutlined from "@mui/icons-material/DnsOutlined";
import useTheme from "@mui/material/styles/useTheme";
import { BarChart } from "@mui/x-charts/BarChart";
import useMetric from "../../hooks/useMetric";
import { Palette } from "@mui/material";
import { LegendRendererProps } from "@mui/x-charts/ChartsLegend";
import MetricCard from "./MetricCard";
import { AxisConfig } from "@mui/x-charts/models";
import { MakeOptional } from "@mui/x-date-pickers/internals";

const legendConfig = (palette: Palette): Partial<LegendRendererProps> => ({
  position: {
    horizontal: "left",
    vertical: "bottom",
  },
  itemMarkHeight: 6,
  labelStyle: {
    fontSize: 12,
    color: palette.grey[700],
  },
  padding: {
    top: 10,
  },
});

const series = [
  { dataKey: "bounced_sessions", label: "Sessions rebondies" },
  { dataKey: "total_sessions", label: "Sessions" },
]

const xAxis: MakeOptional<AxisConfig<'band'>, 'id'> = { scaleType: "band", dataKey: "os", barGapRatio: 0.2 }

const SessionsByOsMetric = () => {
  const metric = useMetric("bounced_sessions_comparison_by_os");

  const { palette } = useTheme();

  return (
    <MetricCard title="Sessions et taux de rebond par OS" Icon={<DnsOutlined />}>
      {metric && metric.length > 0 && (
        <BarChart
          height={200}
          skipAnimation
          dataset={metric}
          margin={{ top: 5, left: 30, right: 30 }}
          xAxis={[xAxis]}
          yAxis={[{ tickMinStep: 1, disableLine: true }]}
          series={series}
          colors={[palette.primary.light, palette.secondary.dark]}
          sx={{
            " .MuiChartsLegend-mark": {
              rx: 4,
            },
            margin: 0,
          }}
          slotProps={{
            bar: {
              ry: 4,
              rx: 4,
            },
            legend: legendConfig(palette),
          }}
          
        />
      )}
      {metric && metric.length === 0 && <div>No data</div>}
    </MetricCard>
  );
};

export default SessionsByOsMetric;
