import OnboardingModal from "components/onboarding/OnboaringModal";
import useOrganizations from "../../hooks/useOrganizations";
import { Organization } from "../../providers/OrganizationsProvider";
import ProjectCard from "components/ProjectCard";

const OrganizationDetails: React.FC<{ organization: Organization }> = ({
  organization,
}) => {
  return (
    <div
      className="p-3 rounded-lg m-3 border-black border bg-green-50"
      key={organization.id}
    >
      <h2 className="m-2 text-xl font-bold">{organization.name}</h2>
      {organization.projects.map((project, index) => (
        <ProjectCard key={index} project={project} />
      ))}
    </div>
  );
};

const ProjectsDashboardView: React.FC = () => {
  const organizations = useOrganizations();

  return (
    <div className="ml-20 flex flex-col mt-32">
      <h1 className="text-2xl font-bold">Projects overview</h1>
      <div className="flex flex-wrap">
        {organizations.map((organization, index) => (
          <OrganizationDetails key={index} organization={organization} />
        ))}
      </div>
      <OnboardingModal />
    </div>
  );
};

export default ProjectsDashboardView;
