import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

interface MetricHeaderProps {
  metrics: {
    title: string;
    value?: string | null;
  }[];
}

const MetricElement: React.FC<MetricHeaderProps["metrics"][number]> = ({
  title,
  value,
}) => {
  return (
    <Box>
      <Typography title={title} variant="subtitle1" color="grey.700" sx={{ textWrap: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width:270 }} >
        {title}
      </Typography>
      
      {value ? (
        <Typography color="common.black" fontSize="1.75rem" fontWeight={590}>
          {value}
        </Typography>
      ) : (
        <Skeleton variant="text" width={100} />
      )}
    </Box>
  );
};

const MetricHeader: React.FC<MetricHeaderProps> = ({ metrics }) => {
  return (
    <Card variant="outlined" sx={{ borderRadius: 4 }} className="">
      <Box className="w-full">
        <Grid container columns={metrics.length} className="h-full">
          {metrics.map((metric, index) => (
            <Grid item xs={1} key={index} className="p-6 flex">
              {index !== 0 && <Divider orientation="vertical" sx={{ mr: 2 }} />}
              <MetricElement {...metric} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Card>
  );
};

export default MetricHeader;
