import useMetric from "../../hooks/useMetric";
import MetricCard from "./MetricCard";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import WebAssetIcon from '@mui/icons-material/WebAsset';

enum Troubles {
  MotorDisorders = 0,
  VisuallyImpaired = 1,
  Epilepsy = 2,
  ADD = 3,
  Learning = 4,
  Elder = 5,
  Dyslexia = 6
}

type TroubleTypeDetails = { 
  label: string
}

const TROUBLE_TYPE_DETAILS: Record<Troubles, TroubleTypeDetails> = {
  [Troubles.MotorDisorders]: { 
    label: "Troubles moteurs"
  },
  [Troubles.VisuallyImpaired]: { 
    label: "Déficience visuelle"
  },
  [Troubles.Epilepsy]: { 
    label: "Épilepsie"
  },
  [Troubles.ADD]: { 
    label: "TDA/H"
  },
  [Troubles.Learning]: { 
    label: "Difficultés d'apprentissage"
  },
  [Troubles.Elder]: { 
    label: "Personnes âgées"
  },
  [Troubles.Dyslexia]: { 
    label: "Dyslexie"
  },
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  color: theme.palette.grey[700],
  border: 0,
  paddingTop: 3,
  paddingBottom: 3,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const MostUsedTruble = () => {
  const metric = useMetric("number_of_trouble_use_per_trouble");

  return (
    <MetricCard title="Troubles les plus utilisés" Icon={<WebAssetIcon />}>
      {metric && metric.length > 0 && (
        <TableContainer className="max-h-[150px] w-full">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Option</StyledTableCell>
                <StyledTableCell align="left">Nombre d'utilisation</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metric.map((row) => (
                <StyledTableRow key={row.trouble_id}>
                  <StyledTableCell component="th" scope="row">
                  {TROUBLE_TYPE_DETAILS[row.trouble_id as Troubles].label}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.nb_activation?.toString()}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {metric && metric.length === 0 && <div>No data</div>}
    </MetricCard>
  );
};

export default MostUsedTruble;
