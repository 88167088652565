import mixpanel, { Dict } from "mixpanel-browser";

const useMixpanelTracking = () => {
  const track = async (event: string, properties?: Dict) => {
    if (process.env.NODE_ENV === "production") mixpanel.track(event, properties);
  };

  return track;
};

export default useMixpanelTracking;
