import {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";
import { DbResultOk, supabase } from "../supabaseClient";
import { Project } from "../hooks/useProject";
import { logError } from "../utils/SentryUtils";
import { CheckAccessClient } from "api-sdk";
import useAuth from "../hooks/useAuth";

export const fetchLastScanQuery = (projectId: string) =>
  supabase
    .from("scans")
    .select(`
      *,
      pages:page_scans(*)
    `)
    .eq("project_id", projectId)
    .order("created_at", { ascending: false })
    .limit(1)
    .single();

export type Scan = DbResultOk<ReturnType<typeof fetchLastScanQuery>>;

type ScanerContextProps = {
  startScan: (project: Project) => Promise<void>;
  isScaning: boolean;
  fetchLastScanFor: (projectId: string) => Promise<void>;
  getProjectLastScan: (projectId: string) => Scan | null | undefined;
  fetchingLastScanProjectIds: string[];
};

const ScanerContext = createContext<ScanerContextProps | null>(null);

const ScanerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [projectLastScanCache, setProjectLastScanCache] = useState<
    Map<string, Scan | null>
  >(new Map());

  const [fetchingLastScanProjectIds, setFetchingLastScanProjectIds] = useState<string[]>([]);

  const { session } = useAuth();
  const [isScaning, setIsScaning] = useState<boolean>(false);

  const client = useMemo(
    () =>
      new CheckAccessClient({
        environment: process.env.REACT_APP_CHECK_ACCESS_API_URL!,
        apiKey: `Bearer ${session.access_token}`
      }),
    [session]
  );

  const fetchLastScanFor = useCallback(async (projectId: string) => {
    if (fetchingLastScanProjectIds.includes(projectId)) return;
    setFetchingLastScanProjectIds((prev) => [...prev, projectId]);

    const { data, error } = await fetchLastScanQuery(projectId);
    
    setProjectLastScanCache((prev) => {
      return new Map(prev.set(projectId, data));
    });

    setTimeout(
      () => setFetchingLastScanProjectIds((prev) => prev.filter((id) => id !== projectId)),
      500
    );

    if (error && error.code !== "PGRST116") { 
      logError("Error while fetching last scan", error);
    }
  }, [fetchingLastScanProjectIds]);

  const startScan = useCallback(
    async (project: Project) => {
      if (isScaning) return;

      setIsScaning(true);

      const res = await client
        .postScan({
          projectId: project.id,
          pageIds: project.pages.map((page) => page.id),
        }, {
          maxRetries: 0
        })
        .catch((error) => {
          logError("Error while starting scan", error);
          return null;
        });

      setIsScaning(false);

      if (!res) return;

      await fetchLastScanFor(project.id);
    },
    [client, fetchLastScanFor, isScaning]
  );

  const getProjectLastScan = useCallback(
    (projectId: string) => projectLastScanCache.get(projectId),
    [projectLastScanCache]
  );

  return (
    <ScanerContext.Provider
      value={{
        startScan,
        isScaning,
        fetchLastScanFor,
        getProjectLastScan,
        fetchingLastScanProjectIds,
      }}
    >
      {children}
    </ScanerContext.Provider>
  );
};

export { ScanerContext, ScanerProvider };
