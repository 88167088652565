import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import React, { ReactNode } from "react";
import {
  ADAPTIFY_ANALYTICS_URL,
  createProjectRoute,
  ADAPTIFY_ANALYTICS_USAGE_URL,
  PROJECTS_URL,
} from "../router";
import useCurrentProject from "../hooks/useCurrentProject";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import HomeIcone from "@mui/icons-material/Home";
import { SvgIconComponent } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";

const activeStyling = {
  backgroundColor: "primary.light",
  color: "secondary.dark",

  "& .MuiListItemIcon-root": {
    color: "secondary.dark",
  },
}

const SidebarButton: React.FC<{
  Icon: SvgIconComponent;
  link: string;
  text: string;
}> = ({ Icon, link, text }) => {
  return (
    <ListItem sx={{ p: 0, mt: 1 }}>
      <ListItemButton
        sx={{
          p: 0,
          borderRadius: 3,

          "&:has(a.active)": activeStyling,
          "&:hover": activeStyling,
          "& .MuiListItemIcon-root": {
            color: "common.white",
          },
        }}
      >
        <NavLink to={link} className={"flex gap-2 items-center p-2 w-full"}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <Icon />
          </ListItemIcon>
          <Typography variant="subtitle1" noWrap>
            {text}
          </Typography>
        </NavLink>
      </ListItemButton>
    </ListItem>
  );
};

const SidebarCategory: React.FC<{
  children: ReactNode;
  title: string;
}> = ({ children, title }) => {
  return (
    <>
      <Typography
        variant="h6"
        noWrap
        sx={{
          mt: 5,
        }}
      >
        {title}
      </Typography>
      {children}
    </>
  );
};

const Sidebar: React.FC<{
  width: number;
}> = ({ width }) => {
  const currentProject = useCurrentProject();
  return (
    <Drawer
      sx={{
        width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width,
          boxSizing: "border-box",
          backgroundColor: "common.black",
          color: "common.white",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <List sx={{ p: 3 }}>
        <SidebarCategory title="Home">
          <SidebarButton
            Icon={HomeIcone}
            link={PROJECTS_URL}
            text="Home"
          />
        </SidebarCategory>
        <SidebarCategory title="Dashboards">
          <SidebarButton
            Icon={DonutLargeIcon}
            link={createProjectRoute(currentProject.id, ADAPTIFY_ANALYTICS_URL)}
            text="Analytics"
          />
          <SidebarButton
            Icon={DonutLargeIcon}
            link={createProjectRoute(currentProject.id, ADAPTIFY_ANALYTICS_USAGE_URL)}
            text="Analytics Adaptify"
          />
        </SidebarCategory>
      </List>
    </Drawer>
  );
};

export { Sidebar, SidebarButton };
