import { redirect, useParams } from "react-router-dom";
import useProject from "./useProject";
import { PROJECTS_URL } from "../router";

const useCurrentProject = () => {
    const projectId = useParams().projectId;

    if (!projectId) {
        redirect(PROJECTS_URL)
        throw new Error("projectId is not defined");
    }

    return useProject(projectId);
};

export default useCurrentProject;