import { createClient, PostgrestError } from "@supabase/supabase-js";
import { Database } from "./types/database.types";
import { Dayjs } from "dayjs";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl && !supabaseAnonKey) {
  throw new Error(
    "REACT_APP_SUPABASE_URL and REACT_APP_SUPABASE_ANON_KEY are not defined"
  );
}

if (!supabaseUrl) {
  throw new Error("REACT_APP_SUPABASE_URL is not defined");
}

if (!supabaseAnonKey) {
  throw new Error("REACT_APP_SUPABASE_ANON_KEY is not defined");
}

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey);

export type Tables<T extends keyof Database["public"]["Tables"]> =
  Database["public"]["Tables"][T]["Row"];

export type InsertTables<T extends keyof Database["public"]["Tables"]> =
  Database["public"]["Tables"][T]["Insert"];

export type Functions<T extends keyof Database["public"]["Functions"]> =
  Database["public"]["Functions"][T];

export type Enums<T extends keyof Database["public"]["Enums"]> =
  Database["public"]["Enums"][T];

export type TableName = keyof Database["public"]["Tables"];

export type DbResult<T> = T extends PromiseLike<infer U> ? U : never;
export type DbResultOk<T> = T extends PromiseLike<{ data: infer U }>
  ? Exclude<U, null>
  : never;

export type DbResultOkOne<T> = DbResultOk<T> extends unknown[]
  ? DbResultOk<T>[number]
  : never;

export type DbResultErr = PostgrestError;

export type MetricFunctions =
  Database["adaptify_analytics_metrics"]["Functions"];
export type MetricFunctionName = keyof MetricFunctions;

export type Metric<T extends MetricFunctionName> = MetricFunctions[T];

export type MetricArgs<T extends MetricFunctionName> = Metric<T>["Args"];
export type MetricResult<T extends MetricFunctionName> = Metric<T>["Returns"];

export type TimeInterval<T = Dayjs> = {
  dtStart: T;
  dtEnd: T;
};
