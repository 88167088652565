import React, { useEffect } from 'react';

const CrispChat: React.FC = () => {
  useEffect(() => {
    (window as any).$crisp = [];
    (window as any).CRISP_WEBSITE_ID = "18ba4c9e-1e0b-4c1e-b56f-f830eb7c3bab";

    const script = document.createElement("script");
    script.src = "https://client.crisp.chat/l.js";
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null; 
};

export default CrispChat;
