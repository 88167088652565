import { useParams } from 'react-router-dom';
import useProject from '../../hooks/useProject';

const ProjectDashboardHomeView: React.FC = () => {
  const projectId = useParams().projectId!;

  const project = useProject(projectId);
  
  return (
    <div>
      <h3>{project.name}</h3>
    </div>
  );
};

export default ProjectDashboardHomeView;
