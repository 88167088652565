import { useMemo } from "react";
import useOrganizations from "./useOrganizations";
import { redirect } from "react-router-dom";
import { PROJECTS_URL } from "../router";
import { Organization } from "../providers/OrganizationsProvider";

// This is to avoid passing `Project | null` to the rest of the app when waiting for redirect
const FAILOVER_PROJECT: Project = { 
  id: "", 
  name: "",
  http_scheme: "http", 
  infos: { 
    interventions: [],
  },
  domain: "",
  pages: []
}

export type Project = Organization["projects"][0];

const useProject = (projectId: string): Project => {
  const organizations = useOrganizations();

  const project = useMemo(
    () =>
      organizations
        .flatMap((organization) => organization.projects)
        .find((project) => project.id === projectId),
    [organizations, projectId]
  );

  if (!project) {
    redirect(PROJECTS_URL);
    return FAILOVER_PROJECT;
  }

  return project;
};

export default useProject;