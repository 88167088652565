import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  DbResultOkOne,
  Enums,
  InsertTables,
  supabase,
} from "../supabaseClient";
import { logError } from "../utils/SentryUtils";
import useAuth from "../hooks/useAuth";
import { Infos } from "@ca/report";

const DEFAULT_INFO: Infos = {
  interventions: [],
};

const fetchOrganizationsQuery = (userId: string) =>
  supabase
    .from("organizations")
    .select(
      `
    *,
    members:users_organizations(user_id),
    self:users_organizations!inner(user_id),
    projects(
      id, 
      http_scheme, 
      name, 
      infos,
      domain,
      pages:project_pages(*)
    )
  `
    )
    .filter("self.user_id", "eq", userId);

export type Organization = DbResultOkOne<
  ReturnType<typeof fetchOrganizationsQuery>
>;

export interface NewOrganizationPayload {
  organization: Omit<InsertTables<"organizations">, "owner_id" | "id">;
  projectPayload: {
    name: string;
    url: URL;
  };
}

type OrganizationsContextProps = {
  organizations: Organization[];
  createOrganization: (payload: NewOrganizationPayload) => Promise<void>;
};

const OrganizationsContext = createContext<OrganizationsContextProps | null>(
  null
);

const OrganizationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { userId } = useAuth();

  const fetchOrganizations = useCallback(async () => {
    const { data, error } = await fetchOrganizationsQuery(userId);

    if (data) setOrganizations(data);
    if (error) logError("Error while fetching organizations", error);
  }, [userId]);

  useEffect(() => {
    fetchOrganizations().finally(() => setIsLoading(false));
  }, [fetchOrganizations]);

  const createOrganization = useCallback(
    async ({ organization, projectPayload }: NewOrganizationPayload) => {
      const { data, error } = await supabase
        .from("organizations")
        .insert({
          ...organization,
          owner_id: userId,
        })
        .select("id")
        .single();

      if (error) {
        logError("enable to insert Organisation:", error);
        return;
      }

      const httpScheme = projectPayload.url.protocol
        ? projectPayload.url.protocol.slice(0, -1)
        : "https";

      const { error: insertProjectError } = await supabase
        .from("projects")
        .insert({
          domain: projectPayload.url.hostname,
          http_scheme: httpScheme as Enums<"http_scheme">,
          organization_id: data.id,
          name: projectPayload.name,
          infos: DEFAULT_INFO,
        });

      if (insertProjectError) {
        logError("enable to insert Organisation:", error);
        return;
      }

      await fetchOrganizations();
    },
    [fetchOrganizations, userId]
  );

  if (isLoading) return <div>Loading organization...</div>;

  return (
    <OrganizationsContext.Provider
      value={{
        organizations,
        createOrganization,
      }}
    >
      {children}
    </OrganizationsContext.Provider>
  );
};

export { OrganizationsContext, OrganizationProvider };
