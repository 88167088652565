import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import ProjectDashboardAppBar from '../../components/ProjectDashboardAppBar';
import { MainSidebar } from 'components/MainSidebar';

const SIDEBAR_WIDTH = 280;

const ProjectMainDashboardRootView: React.FC = () => {
  return (
    <Box className="flex w-full h-full">
      <CssBaseline />
      <MainSidebar width={SIDEBAR_WIDTH} />
      <ProjectDashboardAppBar sidebarWidth={SIDEBAR_WIDTH} />
      <Box component="main" className='flex flex-col flex-grow h-screen'>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default ProjectMainDashboardRootView;
