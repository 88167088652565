import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import * as Sentry from "@sentry/react";
import mixpanel from 'mixpanel-browser';
import initReport from '@ca/report';
import { supabase } from './supabaseClient';
import { supabaseIntegration } from '@supabase/sentry-js-integration'

const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

if (!mixpanelToken) {
  throw new Error('REACT_APP_MIXPANEL_TOKEN is not defined');
}

initReport();

const isDebug = process.env.NODE_ENV === 'development';

mixpanel.init(mixpanelToken, { 
    debug: true, 
    track_pageview: true, 
    persistence: 'localStorage'
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    supabaseIntegration(supabase, Sentry, {
      tracing: true,
      breadcrumbs: true,
      errors: true,
    }),
  ],
  tracesSampleRate: isDebug ? 0.0 : 1.0,
  replaysSessionSampleRate: isDebug ? 0.0 : 0.1,
  replaysOnErrorSampleRate: isDebug ? 0.0 : 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App/>);
