import { useContext } from "react";
import { AnalyticsMetricsContext } from "../providers/AnalyticsMetricsProvider";

const useAnalyticsMetricsProvider = () => {
    const context = useContext(AnalyticsMetricsContext);

    if (!context) {
        throw new Error("useAnalyticsMetricsProvider must be used within a AnalyticsMetricsProviderProvider");
    }

    return context;
}

export default useAnalyticsMetricsProvider;