import { Box, Modal } from "@mui/material";
import OnboardingForm from "./OnboardingForm";
import useOrganizations from "hooks/useOrganizations";
import useAuth from "hooks/useAuth";
import { ConfettiContext } from "providers/ConfettiProvider";
import { useContext, useState, useEffect } from "react";

const OnboardingModal = () => {
  const organizations = useOrganizations();
  const { userDetails } = useAuth();
  const { launchConfetti } = useContext(ConfettiContext)!;

  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);

  useEffect(() => {
    if (isOnboardingModalOpen) return;
    if (organizations.length > 0) return;
    if (!userDetails || !userDetails.has_active_subscription) return;

    setIsOnboardingModalOpen(true);
  }, [isOnboardingModalOpen, organizations.length, userDetails]);

  const handleClose = () => {
    setIsOnboardingModalOpen(false);
    launchConfetti();
  };

  return (
    <Modal
      open={isOnboardingModalOpen}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box>
        <OnboardingForm onClose={handleClose} />
      </Box>
    </Modal>
  );
};

export default OnboardingModal;
