

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  DateTimePicker,
  DateTimePickerSlotsComponentsProps,
} from "@mui/x-date-pickers/DateTimePicker";
import useAnalyticsMetricsProvider from "../../hooks/useAnalyticsMetricsProvider";
import { Dayjs } from "dayjs";

const dateTimePickerSlotProps: DateTimePickerSlotsComponentsProps<Dayjs> = {
  textField: {
    size: "small",
    sx: {
      "& .MuiInputBase-input": {
        color: "primary.light",
      },
    },
  },
};



const AnalyticsDatePicker = () => {
  const { timeInterval, setTimeInterval } = useAnalyticsMetricsProvider();

  return(
      <Box className="flex justify-between items-center mb-5">
        <Typography align="center" variant="h5" color="primary.light">
          Analytics
        </Typography>
        <Box className="flex gap-2">
          <DateTimePicker
            label="Date Start"
            slotProps={dateTimePickerSlotProps}
            value={timeInterval.dtStart}
            onAccept={(dtStart) =>
              dtStart && setTimeInterval((prev) => ({ ...prev, dtStart }))
            }
          />
          <Typography align="center" variant="h5">
            -
          </Typography>
          <DateTimePicker
            label="Date End"
            slotProps={dateTimePickerSlotProps}
            value={timeInterval.dtEnd}
            onAccept={(dtEnd) =>
              dtEnd && setTimeInterval((prev) => ({ ...prev, dtEnd }))
            }
          />
        </Box>
      </Box>
      )}

export default AnalyticsDatePicker;