import { useContext } from "react";
import { ScanerContext } from "../providers/ScanerProvider";

const useScaner = () => {
  const scanerContext = useContext(ScanerContext);

  if (!scanerContext) {
    throw new Error("useScaner must be used within a ScanerProvider");
  }

  return scanerContext;
};

export default useScaner;
