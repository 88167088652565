import { useCallback, useMemo } from "react";
import {
  report_page as wasm_report_page,
  report as wasm_report,
  InterventionRequirement,
  PageReportResult,
  Report,
} from "@ca/report";
import { logError } from "../utils/SentryUtils";
import useCurrentProject from "./useCurrentProject";
import useProjectLastScan from "./useProjectLastScan";

interface UseReporter {
  lastScanReport: Report | null;
  neededInterventions: InterventionRequirement[];
  reportLastScanForPage: (pageId: string) => PageReportResult | null;
}

const useReporter = (): UseReporter => {
  const project = useCurrentProject();
  const { lastScan } = useProjectLastScan(project.id);

  const reportLastScanForPage = useCallback((pageId: string) => {
    if (!lastScan) return null;

    const page = project.pages.find((page) => page.id === pageId);

    if (!page) {
      logError(`Page with id ${pageId} not found`);
      return null;
    }

    const pageLastScan = lastScan.pages.find(
      (page) => page.page_id === pageId
    );

    if (!pageLastScan) {
      console.warn(`No scan found for page with id ${pageId} in last scan`);
      return null;
    }

    // TODO: If theres a parse error for either infos or scan, we should have an api handler to migrate the file versions or log corrupted data
    return wasm_report_page(project.infos, pageLastScan.scan, ["images"]);
  }, [lastScan, project.infos, project.pages]);

  const reportLastScan = useCallback(() => {
    if (!lastScan) return null;

    try {
      return wasm_report(
        project.infos,
        lastScan.pages.map((page) => page.scan),
        ["images"]
      );
    } catch (e) {
      logError("Error while reporting last scan", e);
      return null;
    }
  }, [lastScan, project.infos]);

  const lastScanReportResult = useMemo(reportLastScan, [reportLastScan]);

  return {
    lastScanReport: lastScanReportResult?.report || null,
    neededInterventions: lastScanReportResult?.neededInterventions || [],
    reportLastScanForPage,
  };
};
export default useReporter;
