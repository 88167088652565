import { Database } from "types/database.types";
import useMetric from "../../hooks/useMetric";
import MetricHeader from "./MetricHeader";
import { displaySessionTime } from "utils/dayJsUtils";


const calculateDifference = (avg: Database["adaptify_analytics_metrics"]["CompositeTypes"]["avg_time_by_user_type_record"]) => {
  return (((avg.average_time_adaptify_user!-avg.average_time_non_user!)/avg.average_time_non_user!*100))
}

const GeneralDashboardAnalyticsHeader = () => { 

  const newAdaptifyUser = useMetric("count_new_users_using_adaptify");
  const percentagesOfSessionsUsingAdaptify = useMetric("percentages_of_session_using_adaptify");
  const avgSessionTimeInSecondsByUserType = useMetric("avg_time_by_user_type");
  
  const percentagesOfDifferenceAvgSessionTimeByUserType = avgSessionTimeInSecondsByUserType ? `${calculateDifference(avgSessionTimeInSecondsByUserType!).toFixed(1)}%` : null;
  const avgSessionTimeAdaptifyDisplay = avgSessionTimeInSecondsByUserType ? displaySessionTime(avgSessionTimeInSecondsByUserType.average_time_adaptify_user!) : null;
  const percentagesOfSessionsUsingAdaptifyDisplay = percentagesOfSessionsUsingAdaptify !== null ? `${(percentagesOfSessionsUsingAdaptify * 100).toFixed(1)}%` : null;
  

  const metrics = [
    {
      title: "Nouveaux utilisateur Adaptify",
      value: newAdaptifyUser?.toString(),
    },
    {
      title: "Taux d'utilisation d'Adaptify",
      value: percentagesOfSessionsUsingAdaptifyDisplay,
    },
    {
      title: "Temps moyen d'une session Adaptify",
      value: avgSessionTimeAdaptifyDisplay,
    },
    {
      title: "Différence de temps avec les non utilisateurs",
      value: percentagesOfDifferenceAvgSessionTimeByUserType,
    },
  ]

  return (<MetricHeader metrics={metrics} />)
}

export default GeneralDashboardAnalyticsHeader;