export const getEnumValues = <T>(
  enumObj: Record<string, T>
): Exclude<T, string>[] => {
  return Object.values(enumObj).filter(
    (key): key is Exclude<T, string> => !isNaN(Number(key))
  );
};

export const getEnumLabel = (enumObj: object, el: unknown): string => {
  return enumObj[el as keyof typeof enumObj];
};
