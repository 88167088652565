import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TimeOnPageByUserType from "../../../components/metrics/TimeOnPageByUserType";
import GeneralDashboardAnalyticsAdaptifyHeader from "../../../components/metrics/GeneralDashboardAnalyticsAdaptifyHeader";
import MostUsedTruble from "../../../components/metrics/MostUsedTruble";
import MostUsedOptions from "../../../components/metrics/MostUsedOptions";
import PageWithMostAdaptifyUsage from "../../../components/metrics/PageWithMostAdaptifyUsage";
import AdaptifySessionsByCountryMetric from "../../../components/metrics/AdaptifySessionsByCountryMetric";
import AnalyticsDatePicker from "components/metrics/AnalyticsDatePicker";

const AdaptifyAnalyticsAdaptifyUsage: React.FC = () => {

  return (
    <Box className="flex flex-col flex-grow p-6 pt-9 mb-6">
      <AnalyticsDatePicker/>
      <Box>
        <GeneralDashboardAnalyticsAdaptifyHeader />
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(6, 1fr)"
        gridTemplateRows={"1fr 0.75fr"}
        className="flex-grow"
        sx={{
          pt: '1.25rem',
          gridColumnGap: "1.25rem",
          gridRowGap: "1.25rem",
        }}
      >
        <Box gridArea="1 / 1 / 1 / 4">
          <TimeOnPageByUserType />
        </Box>
        <Box gridArea="1 / 4 / 1 / 7">
          <AdaptifySessionsByCountryMetric />
        </Box>

        <Box gridArea="2 / 1 / 2 / 3">
          <MostUsedTruble />
        </Box>
        <Box gridArea="2 / 3 / 2 / 5">
          <PageWithMostAdaptifyUsage />
        </Box>
        <Box gridArea="2 / 5 / 2 / 7">
          <MostUsedOptions />
        </Box>
      </Box>
      <Grid container spacing={3} sx={{ flexGrow: 1 }}></Grid>
    </Box>
  );
};

export default AdaptifyAnalyticsAdaptifyUsage;
