import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import LogoutIcone from "@mui/icons-material/Logout";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import useTheme from "@mui/material/styles/useTheme";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useContext, useState } from "react";
import { AuthContext } from "providers/AuthProvider";
import { useNavigate } from "react-router-dom";

const ProjectDashboardAppBar: React.FC<{ sidebarWidth: number }> = ({
  sidebarWidth,
}) => {
  const {signOut} = useContext(AuthContext)!;
  const { palette } = useTheme();
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const navigate = useNavigate();

  const handleSingOut = () => {
    signOut();
    navigate('/');
  }


  const handleCloseSnackBar = () => setIsSnackBarOpen(false);

  const handleShareClick = () => {
    navigator.clipboard.writeText(window.location.href);
    console.log("Copied to clipboard!");
    setIsSnackBarOpen(true);
  };

  return (
    <AppBar 
      sx={{
        position: "fixed",
        width: `calc(100% - ${sidebarWidth}px)`,
        ml: `${sidebarWidth}px`,
        backgroundColor: "white",
        borderBottomColor: "divider",
        borderBottomWidth: 1,
        boxShadow: "none",
      }}
    >
      <Toolbar>
        <Box className="flex-grow" />
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <IconButton
            size="large"
            aria-label="copy url to clipboard"
            color="inherit"
            onClick={handleShareClick}
          >
            <ShareOutlinedIcon htmlColor={palette.primary.light} />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <IconButton
            size="large"
            aria-label="copy url to clipboard"
            color="inherit"
            onClick={handleSingOut}
          >
            <LogoutIcone htmlColor={palette.primary.light} />
          </IconButton>
        </Box>
        <Divider orientation="vertical" />
      </Toolbar>
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Le lien à été copié dans le presse-papier!
        </Alert>
      </Snackbar>
    </AppBar>
  );
};

export default ProjectDashboardAppBar;
