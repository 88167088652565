import { useEffect, useState } from "react";
import useCurrentProject from "./useCurrentProject";
import { supabase } from "../supabaseClient";
import { logError } from "../utils/SentryUtils";

const useCurrentDomainId = () => {
  const [domainId, setDomainId] = useState<string | null>(null);
  const currentProject = useCurrentProject();
  
  useEffect(() => {
    const fetch = async () => { 
      const { data, error } = await supabase.schema("public").rpc("get_analytics_domain_id", { 
        project_id: currentProject?.id,
      });

      if(error) logError("Error while fetching domain", error);

      setDomainId(data);
    };

    fetch();
  }, [currentProject?.id])
  
  return domainId;
};

export default useCurrentDomainId;