import useMetric from "../../hooks/useMetric";
import MetricCard from "./MetricCard";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import { logError } from "utils/SentryUtils";

enum AccessibilityOptionType {
  ZoomEcran = 0,
  TextZoom = 1,
  SmartNavigation = 2,
  StopBlinking = 3,
  ImageDescription = 4,
  ReadingGuide = 5,
  Contrast = 6,
  HighlightLinks = 7,
  HighlightTitles = 8,
  BlueLightReducer = 9,
  AudioReader = 10,
  VoiceTyping = 11,
  HighLightFocus = 12,
  VirtualKeyboard = 13,
  Dictionnary = 14,
  ReadableFont = 15,
  ImprovedCursor = 16,
}

type AccessibilityOptionTypeDetails = {
  label: string;
};

const ACCESSIBILITY_OPTION_DETAILS: Record<
  AccessibilityOptionType,
  AccessibilityOptionTypeDetails
> = {
  [AccessibilityOptionType.ZoomEcran]: {
    label: "Zoom de l'écran",
  },
  [AccessibilityOptionType.SmartNavigation]: {
    label: "Navigation intelligente",
  },
  [AccessibilityOptionType.StopBlinking]: {
    label: "Arrêter le clignotement",
  },
  [AccessibilityOptionType.VoiceTyping]: {
    label: "Écriture avec la voix",
  },
  [AccessibilityOptionType.AudioReader]: {
    label: "Lecteur audio",
  },
  [AccessibilityOptionType.Contrast]: {
    label: "Contraste",
  },
  [AccessibilityOptionType.BlueLightReducer]: {
    label: "Réducteur de lumière bleue",
  },
  [AccessibilityOptionType.ImprovedCursor]: {
    label: "Curseur amélioré",
  },
  [AccessibilityOptionType.TextZoom]: {
    label: "Zoom de texte",
  },
  [AccessibilityOptionType.ImageDescription]: {
    label: "Description d'image",
  },
  [AccessibilityOptionType.ReadingGuide]: {
    label: "Guide de lecture",
  },
  [AccessibilityOptionType.HighlightLinks]: {
    label: "Mettre en surbrillance les liens",
  },
  [AccessibilityOptionType.HighlightTitles]: {
    label: "Mettre en surbrillance les titres",
  },
  [AccessibilityOptionType.HighLightFocus]: {
    label: "Mettre en surbrillance sous le curseur",
  },
  [AccessibilityOptionType.VirtualKeyboard]: {
    label: "Clavier virtuel",
  },
  [AccessibilityOptionType.Dictionnary]: {
    label: "Dictionnaire",
  },
  [AccessibilityOptionType.ReadableFont]: {
    label: "Police lisible",
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  color: theme.palette.grey[700],
  border: 0,
  paddingTop: 3,
  paddingBottom: 3,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const MostUsedOptions = () => {
  const metric = useMetric("number_of_option_use_per_option");

  const findLabelFor = (option_id: number) => {
    try {
      return ACCESSIBILITY_OPTION_DETAILS[option_id as AccessibilityOptionType]
        .label;
    } catch {
      logError(`unknow option id ${option_id}`);
      return null;
    }
  };

  return (
    <MetricCard title="Options les plus utilisées" Icon={<WebAssetIcon />}>
      {metric && metric.length > 0 && (
        <TableContainer className="max-h-[150px] w-full">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Option</StyledTableCell>
                <StyledTableCell align="left">
                  Nombre d'utilisation
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metric
                .filter((row) => findLabelFor(row.option_id))
                .map((row) => (
                  <StyledTableRow key={row.option_id}>
                    <StyledTableCell component="th" scope="row">
                      {findLabelFor(row.option_id)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.nb_activation?.toString()}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {metric && metric.length === 0 && <div>No data</div>}
    </MetricCard>
  );
};

export default MostUsedOptions;
