import { Tooltip } from "@mui/material";
import { Organization } from "providers/OrganizationsProvider";
import WarningIcon from "@mui/icons-material/Warning";

interface ProjectCardProps {
  project: Organization["projects"][number];
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
  return (
    <div key={project.id}>
      <h3 className="m-2 text-lg">{project.name}</h3>

      <div className="flex flex-row justify-start items-center">
        <div
          className="cursor-pointer font-bold m-2 border border-black rounded-lg p-1.5 bg-green-950 
              text-white hover:bg-green-200 hover:text-black transition flex flex-row"
        >
          Analytique:&nbsp; <p className="font-normal">{project.domain}</p>
        </div>
        <Tooltip title="Bientôt disponible">
          <WarningIcon />
        </Tooltip>
      </div>

      <div className="flex flex-row justify-start items-center">
        <div
          className="cursor-pointer font-bold m-2 border border-black rounded-lg p-1.5 bg-green-950 
              text-white hover:bg-green-200 hover:text-black transition flex flex-row"
        >
          Check-Access:&nbsp; <p className="font-normal"> {project.domain}</p>
        </div>
        <Tooltip title="Bientôt disponible">
          <WarningIcon />
        </Tooltip>
      </div>
    </div>
  );
};

export default ProjectCard;
