import { LineChart } from "@mui/x-charts/LineChart";
import useMetric from "../../hooks/useMetric";
import MetricCard from "./MetricCard";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import dayjs, { Dayjs } from "dayjs";
import useTheme from "@mui/material/styles/useTheme";
import { MetricResult, TimeInterval } from "../../supabaseClient"; 
import useAnalyticsMetricsProvider from "../../hooks/useAnalyticsMetricsProvider";
import { useMemo } from "react";

type FormatedMetricResult = (Omit<MetricResult<"avg_pages_per_sessions_per_day">[number], 'day'> & { day: Dayjs })[];

const gapFillDates = (
  metric: MetricResult<"avg_pages_per_sessions_per_day">, 
  timeInterval: TimeInterval
): FormatedMetricResult => {
  let date = dayjs(timeInterval.dtStart);
  const lastDate = dayjs(timeInterval.dtEnd);

  const dates = [date];
  while (date.isBefore(lastDate)) {
    date = date.add(1, 'day');
    dates.push(date);
  }

  return dates.map((date) => ({
    day: date,
    avg_pages_per_session: 
      metric.find((m) => dayjs(m.day).isSame(date, 'day'))?.avg_pages_per_session || 0,
  }));
}

const LinearGradientDefs = () => {
  const { palette } = useTheme();
  return (
    <defs>
      <linearGradient id="gradient" x1="100%" x2="100%" y1="100%" y2="0%">
        <stop offset="0%" stopColor={palette.secondary.light} stopOpacity={0} />
        <stop offset="100%" stopColor={palette.secondary.light} stopOpacity={0.6}/>
      </linearGradient>
    </defs>
  );
}

const PagesPerEntranceMetric = () => {
  const { timeInterval } = useAnalyticsMetricsProvider();
  const metric = useMetric("avg_pages_per_sessions_per_day");

  const data = useMemo(() => gapFillDates(metric || [], timeInterval), [metric, timeInterval]);

  const xAxisData = data?.map(({ day }) => dayjs(day));
  const yAxisData = data?.map(
    ({ avg_pages_per_session }) => avg_pages_per_session
  );

  const { palette } = useTheme();

  return (
    <MetricCard title="Pages par visite" Icon={<LanguageOutlinedIcon />}>
      {metric && metric.length > 0 && (
        <LineChart
          height={150}
          xAxis={[
            {
              data: xAxisData,
              scaleType: "time",
              tickMinStep: 1000 * 60 * 60 * 10,
              valueFormatter: (date) => dayjs(date).format("DD/MM"),
            },
          ]}
          margin={{ top: 5, right: 30, left: 30, bottom: 20 }}
          yAxis={[{ tickMinStep: 1 }]}
          sx={{
            " .MuiAreaElement-root": {
              fill: 'url(#gradient)',
            },
          }}
          series={[
            {
              data: yAxisData,
              color: palette.secondary.light,
              area: true,
              showMark: false,
            },
          ]}
        >
          <LinearGradientDefs />
        </LineChart>
      )}
      {metric && metric.length === 0 && <div>No data</div>}
    </MetricCard>
  );
};

export default PagesPerEntranceMetric;
