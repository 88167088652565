import useTheme from "@mui/material/styles/useTheme";
import { PieChart } from "@mui/x-charts/PieChart";
import useMetric from "../../hooks/useMetric";
import { Palette } from "@mui/material";
import MetricCard from "./MetricCard";
import { LegendRendererProps } from "@mui/x-charts";
import { useMemo } from "react";
import DevicesOutlined from "@mui/icons-material/DevicesOutlined";
import { MetricResult } from "../../supabaseClient";

const getColorsOrder = (palette: Palette) => [palette.primary.light, palette.secondary.dark, "#F4802C", "#FA5087", "#50EBFA", "#A5846C", "#6AB3BA"]

const legendConfig = (
  palette: Palette, 
  data: ReturnType<typeof computeMetricData>
): Partial<LegendRendererProps> => ({
  itemMarkHeight: 6,
  labelStyle: {
    fontSize: 16,
    color: palette.grey[700],
  },
  direction: 'column',
  position: {
    horizontal: "right",
    vertical: "middle",
  },
  seriesToDisplay: data.slice(0, 3).map((el, index) => ({
    id: index,
    label: el.label,
    color: getColorsOrder(palette)[index]
  }))
});

const computeMetricData = (metric: MetricResult<"new_sessions_by_device">) => { 
  const totalSessions = metric.reduce((acc, { new_sessions }) => acc + new_sessions, 0);

  return metric.map(({ device, new_sessions }) => {
    const percentage = Math.round((new_sessions / totalSessions) * 100);
    return {
      label: `${device.substring(0, 10)}\n${percentage}%`,
      value: new_sessions,
    }
  })
};

const SessionsByDevicesMetric = () => {
  const metric = useMetric("new_sessions_by_device");

  const data = useMemo(() => metric && computeMetricData(metric), [metric]);

  const { palette } = useTheme();

  return (
    <MetricCard title="Plateformes" Icon={<DevicesOutlined />}>
      {data && data.length > 0 && (
        <PieChart
          height={150}
          series={[{ 
            data,
            innerRadius: '60%',
            outerRadius: '100%',
          }]}
          margin={{ left: -20 }}
          sx={{
            " .MuiChartsLegend-mark": {
              rx: 4,
            },
            " .MuiChartsLegend-series tspan:last-child": {
              fontSize: 22,
              fontWeight: 590,
              color: palette.common.black,
            }
          }}
          slotProps={{
            legend: legendConfig(palette, data),
          }}
          colors={getColorsOrder(palette)} 
        />
      )}
      {data && data.length === 0 && (<div>No data</div>)}
    </MetricCard>
  );
}

export default SessionsByDevicesMetric;